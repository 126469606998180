<template>
  <div>
    <this-header />
    <div class="container-new pb-5">
      <div class="d-flex align-items-center pd2 mb-1">
        <router-link :to="{ name: 'tanghuay-home' }">
          <button
            class="bnt-home"
          >
            <i class="fas fa-home" />
          </button>
        </router-link>
        <div class="txt-right">
          <div class="font-weight-bolder">
            <span class="">ถอนเงิน</span>
          </div>
        </div>
      </div>
      <div class="pd2">
        <div class="bg-lot p-1">
          <div class="header_txt1">
            <h3>ถอนเงินเข้าบัญชีธนาคาร</h3>
          </div>
          <div class="text-center mt-1">
            <!-- <u class="text-danger">"กรุณาใช้บัญชีที่ท่านสมัครโอนมาเท่านั้น"</u> -->
            <p class="text-white">
              *ระบบจะถอนเงินเข้าบัญชี ภายในเวลา 1-2 นาที
            </p>

            <div class="depo_min">
              <p class="m-0">
                ถอนเงินขั้นต่ำ 100 บาท
              </p>
            </div>
          </div>

          <div class="card-bank mt-1 p-1">
            <div class="d-flex align-items-center">
              <div
                style="
              background-color: #4e2e7f;
              border-radius: 6px;
              width: 35px;
              height: 35px;
              padding: 5px;
              margin-right: 5px;
            "
              >
                <img
                  src="@/assets/Bank/SCB.png"
                  alt="bank-scb"
                  height="25"
                >
              </div>
              <div>
                <h5
                  class="m-0 font-weight-bolder"
                  style="color: #000"
                >
                  ธนาคารไทยพาณิชย์ จำกัด (มหาชน)
                </h5>
              </div>
            </div>

            <div class="mt-1 mb-1 d-flex justify-content-between">
              <img
                src="/chip.png"
                alt="chip"
                height="40"
              >
            </div>

            <div>
              <div class="box-bank">
                <h4 class="m-0 dark font-weight-bolder">
                  <!-- {{ agent.dip_bank_accno }} -->
                  1234567890
                </h4>
              </div>
              <div>
                <h5 class="m-0 dark">
                  <!-- {{ agent.dip_bank_accname }} -->
                  นาย ธนาคาร ไทยพาณิชย์
                </h5>
              </div>
            </div>
          </div>

          <div class="box_with mt-1">
            <input
              id="balance"
              v-model="balance"
              type="number"
              name="balance"
              placeholder="กรอกจำนวนเงินที่ต้องการถอน"
              class="form-withdraw"
            >

            <div class="text-center mt-1">
              <button
                class="btn btn-block btn-100 btn-gold"
                @click="withdraw"
              >
                ถอนเงิน
              </button>
            </div>
          </div>
        </div>

        <div class="bg-lot p-1 mt-1">
          <div class="text-left text-head-hisotry text-white">
            ประวัติการเติมเครดิต [รายการล่าสุด 5 รายการ]
          </div>

          <div
            class="
        w-100
        border-solid
        rounded-lg
        text-color
        bg-table
        border-10
        table-responsive
      "
          >
            <table
              role="table"
              aria-busy="false"
              aria-colcount="3"
              class="table b-table table-sm"
            >
              <thead role="rowgroup">
                <tr role="row">
                  <th
                    role="columnheader"
                    scope="col"
                    aria-colindex="1"
                    class="small text-left pl-1"
                  >
                    <div>วันที่/เวลา</div>
                  </th>
                  <th
                    role="columnheader"
                    scope="col"
                    aria-colindex="2"
                    class="small text-center pr-1"
                  >
                    <div>จำนวนเงิน</div>
                  </th>
                  <th
                    role="columnheader"
                    scope="col"
                    aria-colindex="3"
                    class="small text-center pr-1"
                  >
                    <div>สถานะ</div>
                  </th>
                </tr>
              </thead>
              <tbody
                v-if="depData.length > 0"
                role="rowgroup"
              >
                <tr
                  v-for="(key, index) in depData"
                  :key="index._id"
                  role="row"
                  class="b-table-empty-row"
                >
                  {{ withdrawData }}
                  <td>{{ key.timestamp }}</td>
                  <td class="text-center">
                    {{ key.amount }}บาท
                  </td>
                  <td class="text-center">
                    <b-badge
                      v-if="key.status === 'success'"
                      variant="light-success"
                    ><small>สำเร็จ</small>
                    </b-badge>

                    <b-badge
                      v-if="key.status === 'waiting'"
                      variant="light-warning"
                    ><small>รอดำเนินการ</small>
                    </b-badge>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr
                  role="row"
                  class="b-table-empty-row"
                >
                  <td
                    colspan="3"
                    role="cell"
                  >
                    <div
                      role="alert"
                      aria-live="polite"
                    >
                      <div class="text-color text-center pt-2 pb-2 fs-14">
                        <img
                          src="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/facebook/327/card-file-box_1f5c3-fe0f.png"
                          alt="empty"
                          height="30"
                        > ไม่พบข้อมูล
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <this-footer />
  </div>
</template>

<script>
import ThisHeader from './component/ThisHeader.vue'
import ThisFooter from './component/ThisFooter.vue'

export default {
  components: {
    ThisHeader,
    ThisFooter,
  },
  data() {
    return {
      depData: [],
    }
  },
  methods: {
    copy() {
      this.$bvToast.toast('คัดลอกบัญชีเรียบร้อย', {
        title: 'สำเร็จ',
        variant: 'success',
        solid: true,
      })
    },
  },
}
</script>
